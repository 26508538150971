import router from "@/router";
import {AUTH_KEY} from "@/services/auth";

export default function useToken(): string | undefined {
  const itemStr = localStorage.getItem(AUTH_KEY);
  // if the item doesn't exist, return
  if (!itemStr) {
    return;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    router.push({ name: 'logout' });
  } else {
    return item.value;
  }
}
