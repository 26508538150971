import { gql } from '@apollo/client/core';

export const NOMINATED_USERS = gql`
  query getNominatedUsers {
    user {
      nominatedUsers {
        id
        firstName
        lastName
        username
      }
    }
  }
`;
