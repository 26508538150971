import { gql } from '@apollo/client/core';
import { USER_DETAILS as UserDetailsFragment } from '../fragments/user';

export const COMMENT_DETAILS = gql`
  ${UserDetailsFragment}
  fragment CommentDetails on Comment {
    id
    reflection
    publishedAt
    user {
      ...UserDetails
    }
    commentsCount
  }
`;
