
import { defineComponent } from 'vue';

export const BaseSelect = defineComponent({
  name: 'BaseSelect',
  props: {
    label: {
      type: String,
      default: ''
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    hideIcon: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
});

export default BaseSelect;
