
import {defineComponent, inject, onMounted, PropType, ref, watch} from 'vue';
import { Grok } from "@/types/generated/types";
import router from "@/router";
import useTimeSinceFormatter from "@/composables/useTimeSinceFormatter";
import useNumberFormatter from "@/composables/useNumberFormatter";
import {useMutation} from "@vue/apollo-composable";
import {UPSERT_GROK_INTERACTION} from "@/graphql/mutations/upsertGrokInteraction";
import {apolloClient} from "@/main";
import {UserKey} from "@/types/types";
import {UPDATE_GROK} from "@/graphql/mutations";
import AddToPlaylist from "@/pages/groks/AddToPlaylist.vue";
import {BaseSidebar} from "@/components";

export const BaseVideo = defineComponent({
  name: 'BaseVideo',
  components: {AddToPlaylist, BaseSidebar},
  emits: ['toggle-comments', 'open-login-sidebar', 'last-played'],
  props: {
    grok: {
      type: Object as PropType<Grok>,
      required: true
    },
    elementId: {
      type: String,
      required: false
    },
    showDetails: {
      type: Boolean,
      default: true
    },
    scroll: {
      type: Number,
      default: 0
    }
  },
  setup(props, ctx) {
    const user = inject(UserKey);
    const playVideo = ref(false);
    const currentTime = ref(0);
    const element = ref();
    const returnToScreen = ref(true);
    const loading = ref(false);
    const allowAutoplay = ref(true);
    const videoId = props.elementId ? props.elementId : props.grok.id;

    onMounted(() => {
      element.value = document.getElementById(videoId);
      element.value.addEventListener('loadedmetadata', function() {
        // Safari iOS will not preload video, so readyState always 1
        // Check if video ready onplay event
        loadVideoInView();
      });
    });

    watch(() => props.scroll, () => {
      if (!loading.value) { loadVideoInView(); }
    });

    const loadingRetry = ref(0);
    function manageVideoState() {
      // avoid repeat check on scroll
      playVideo.value = true;

      if (videoReady()) {
        loading.value = false;
        loadingRetry.value = 0;
        element.value.currentTime = Math.floor(currentTime.value);
        element.value.play().then(() => {
          console.log('playing', props.grok.title);
          allowAutoplay.value = true;
          ctx.emit('last-played');
        }).catch(() => {
          // Autoplay was prevented.
          console.log('video cannot autoplay');
          allowAutoplay.value = false;
        });
      } else if (disableAutoplay()) {
        console.log('autoplay disabled', props.grok.title);
        loadingRetry.value = 0;
        loading.value = false;
        allowAutoplay.value = false;
      } else {
        // First retry to check if play will work
        // Start loading spinner after that
        loading.value = loadingRetry.value > 0;
        loadingRetry.value = loadingRetry.value + 1;
        setTimeout(() => { manageVideoState(); }, 100 );
      }
    }

    function videoReady() : boolean {
      console.log('readyState for ', props.grok.title, element.value.readyState);
      return element.value.readyState > 3;
    }

    function disableAutoplay() : boolean {
      console.log('check disableAutoplay for ', props.grok.title, loadingRetry.value, element.value.readyState);
      return loadingRetry.value > 0 && element.value.readyState < 2;
    }

    function loadVideoInView() {
      const vh =
          window.innerHeight || document.documentElement.clientHeight;
      if(element.value) {
        const { top, bottom } = element.value.getBoundingClientRect();
        const onScreen = (vh - top > 0 && bottom > 0);

        const height = bottom - top;
        // top more than halfway up and within video height of top
        let trigger = (vh < (2 * height)) ? vh / 2 : height;
        let play = top < trigger && bottom > trigger && (returnToScreen.value || (!element.value.ended && !(element.value.paused && currentTime.value > 0)));

        // listener events
        element.value.onplay = function() {
          if (!playVideo.value || !allowAutoplay.value) {
            manageVideoState();
          }
        };
        element.value.onpause = function() {
          playVideo.value = false;
          returnToScreen.value = false;
          if (element.value) {
            currentTime.value = element.value.currentTime;
            recordInteraction(element.value.currentTime, element.value.duration);
          }
        };

        if(play && !playVideo.value) {
          manageVideoState();
        } else if(playVideo.value && !play) {
          element.value.pause();
        } else if (!onScreen && !returnToScreen.value) {
          returnToScreen.value = true;
        }
      }
    }

    function openGrok() {
      let route = '/grok/' + props.grok.id;
      router.push(route);
    }

    const { mutate: grokInteraction } = useMutation(UPSERT_GROK_INTERACTION, {
      errorPolicy: 'all'
    });

    const { mutate: unpublishGrok } = useMutation(UPDATE_GROK, {
      errorPolicy: 'all'
    });

    function canGrok() {
      if (user?.value) {
        callGrokIt();
      } else {
        ctx.emit('open-login-sidebar');
      }
    }

    function callGrokIt() {
      const variables = {
        input: {
          grokId: props.grok.id,
          favorite: true
        }
      };

      return grokInteraction(variables).then((response) => {
        if (response && response.errors) {
          console.log('grok favorite not recorded');
        } else {
          apolloClient.reFetchObservableQueries();
        }
      });
    }

    function recordInteraction(playedUntil, length) {
      const variables = {
        input: {
          grokId: props.grok.id,
          playedUntil: playedUntil,
          length: length
        }
      };

      return grokInteraction(variables);
    }

    function callToggleComments() {
      ctx.emit('toggle-comments');
    }

    function viewUser(username, page = '') {
      if (user?.value) {
        let route = '/user/' + username + page;
        router.push(route);
      } else {
        ctx.emit('open-login-sidebar');
      }
    }

    function unpublish() {
      const variables = {
        input: {
          id: props.grok.id,
          published: false
        }
      };

      return unpublishGrok(variables).then((response) => {
        if (response && response.errors) {
          console.log('error unpublishing');
        } else {
          apolloClient.reFetchObservableQueries();
          console.log('grok unpublished');
        }
      });
    }

    const playlistSidebarOpen = ref(false);
    function togglePlaylistSidebar() {
      playlistSidebarOpen.value = !playlistSidebarOpen.value;
    }

    return {
      openGrok,
      useTimeSinceFormatter,
      useNumberFormatter,
      canGrok,
      callToggleComments,
      viewUser,
      user,
      unpublish,
      videoId,
      loading,
      togglePlaylistSidebar,
      playlistSidebarOpen
    };
  }
});

export default BaseVideo;
