
import {defineComponent} from 'vue';
import {useMutation, useQuery, useResult} from "@vue/apollo-composable";
import {USER_FOLLOW_DETAILS} from "@/graphql/queries/userFollowDetails";
import {CREATE_FOLLOW} from "@/graphql/mutations/createUserFollow";
import {DELETE_FOLLOW} from "@/graphql/mutations/deleteUserFollow";
import router from "@/router";

export default defineComponent({
  name: 'FollowInfo',
  emits: ['close-sidebar'],
  props: {
    user: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const variables = {
      input: { id: props.user.id }
    };

    const {
      result: followQueryResult,
      loading: followQueryLoading
    } = useQuery(USER_FOLLOW_DETAILS, { id: props.user.id });

    const followDetails = useResult(followQueryResult, 'user');

    const { mutate: followUser } = useMutation(CREATE_FOLLOW, {
      errorPolicy: 'all',
      refetchQueries: [{ query: USER_FOLLOW_DETAILS, variables: { id: props.user.id } }]
    });

    const { mutate: unfollowUser } = useMutation(DELETE_FOLLOW, {
      errorPolicy: 'all',
      refetchQueries: [{ query: USER_FOLLOW_DETAILS, variables: { id: props.user.id } }]
    });

    function callFollowUser() {
      return followUser(variables);
    }

    function callUnfollowUser() {
      return unfollowUser(variables);
    }

    function showUser(details: string) : void {
      let route = '/user/' + props.user.username + details;
      ctx.emit('close-sidebar');
      router.push(route);
    }

    return { followDetails, followQueryLoading, callFollowUser, callUnfollowUser, showUser };
  }
});
