import { gql } from '@apollo/client/core';

export const DELETE_INVITATION = gql`
  mutation DeleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input) {
      invitation {
        id
        userId
        email
        accepted
      }
    }
  }
`;
