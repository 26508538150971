import { createApp, provide, h } from 'vue';
import {ApolloClient, InMemoryCache, HttpLink, ApolloLink} from '@apollo/client/core';
import { ApolloClients } from '@vue/apollo-composable';
import { setContext } from '@apollo/client/link/context';
import App from './App.vue';
import router from './router';
import "./registerServiceWorker.ts";

// Global Components - removes need to import at component level
import { Loading, Container } from '@/components';

import '@/assets/css/tailwind.css';
import '@/assets/css/globals.css';
import '@/assets/css/buttons.css';
import '@/assets/css/tooltip.css';
import '@/assets/css/links.css';
import '@/assets/css/scroll.css';
import '@/assets/css/typography.css';
import { typePolicies } from './graphql/queries/typePolicies';
import { getMainDefinition } from "apollo-utilities";
import omitDeep from 'omit-deep-lodash';
import {FragmentDefinitionNode, OperationDefinitionNode} from "graphql";
import useToken from "@/composables/useToken";

const httpLink = new HttpLink({
  uri: `${process.env.VUE_APP_API_BASE_URL}/graphql`
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = useToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  // more keys like timestamps could be included here
  const keysToOmit = ['__typename'];

  const def : OperationDefinitionNode | FragmentDefinitionNode = getMainDefinition(operation.query);
  if (def && ("operation" in def) && def.operation === 'mutation') {
    operation.variables = omitDeep(operation.variables, keysToOmit);
  }
  return forward ? forward(operation) : null;
});

// create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from([cleanTypenameLink, authLink, httpLink]),
  cache: new InMemoryCache(typePolicies)
});

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient
    });
  },
  render: () => h(App)
});

app.use(router);
app.component('Loading', Loading);
app.component('Container', Container);
app.mount('#app');
