
import { defineComponent } from 'vue';

export const FormValidation = defineComponent({
  name: 'FormValidation',
  props: {
    errorMessage: {
      type: String,
      default: 'Please fill out this field.'
    },
    errorDetails: {
      type: Array,
      required: true
    },
    errorFields: {
      type: Object,
      required: true
    }
  }
});

export default FormValidation;
