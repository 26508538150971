import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "absolute loading inset-0 bg-gray-900 bg-opacity-50 z-50 flex flex-col justify-center" }
const _hoisted_2 = { class: "spinner m-auto ease-linear rounded-full border-8 border-t-8 border-gray-800 h-24 w-24" }
const _hoisted_3 = { class: "mt-4 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.message), 1)
    ])
  ]))
}