import { gql } from '@apollo/client/core';

export const CREATE_INVITATION = gql`
  mutation CreateInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitation {
        id
        userId
        email
        accepted
        role {
          name
        }
      }
    }
  }
`;
