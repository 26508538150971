
import { INVITATIONS } from '../../../graphql/queries/invitations';
import { useResult, useMutation } from '@vue/apollo-composable';
import { DELETE_INVITATION } from '@/graphql/mutations/deleteInvitation';
import {
  DeleteInvitationInput,
} from '@/types/generated/types';
import useGraphqlQuery from "@/composables/useGraphqlQuery";

export default {
  setup() {
    let deleteInvitationErrors: Array<string> = [];

    const {
      result: invitationResult,
      error: invitationQueryError,
      loading: invitationsLoading
    } = useGraphqlQuery(INVITATIONS, { variables: { includeAccepted: false } });

    const invitations = useResult(
      invitationResult,
      null,
      (data) => data.invitations
    );

    const { mutate: deleteInvitation } = useMutation(DELETE_INVITATION, {
      errorPolicy: 'all',
      refetchQueries: [{ query: INVITATIONS }]
    });

    function deleteInvite(inviteId: string) {
      let input: DeleteInvitationInput = { id: inviteId };

      const variables = {
        input: input
      };

      return deleteInvitation(variables).then((response) => {
        if (response && response.errors) {
          deleteInvitationErrors = JSON.parse(JSON.stringify(response.errors));
        }
      });
    }

    return {
      invitationQueryError,
      invitationsLoading,
      invitations,
      deleteInvite,
      deleteInvitationErrors
    };
  }
};
