
import { defineComponent } from 'vue';

export const BaseTable = defineComponent({
  name: 'BaseTable',
  props: {
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    headerClass: {
      type: String,
      default: null
    },
    tableSpacing: {
      type: Boolean,
      default: true
    }
  }
});

export default BaseTable;
