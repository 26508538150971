
import {defineComponent, inject, ref} from 'vue';
import { UserKey } from '../../types/types';
import {BaseDropdown, BaseSidebar} from '@/components';
import UnauthenticatedNav from './UnauthenticatedNav.vue';
import NotificationCount from './NotificationCount.vue';
import PendingReviewCount from './PendingReviewCount.vue';
import UserMenu from "@/components/Navigation/UserMenu.vue";
import GrokForm from "@/pages/groks/GrokForm.vue";
import PersonalInfo from "@/pages/account/PersonalInfo.vue";
import UsersWrapper from "@/pages/account/Invitations/UsersWrapper.vue";
import router from "@/router";
import Search from "@/components/Search.vue";
import PWAPrompt from "@/components/PWAPrompt.vue";
import PWAUpdate from "@/components/PWAUpdate.vue";

const Navigation = defineComponent({
  components: {
    UserMenu,
    BaseDropdown,
    UnauthenticatedNav,
    BaseSidebar,
    PersonalInfo,
    UsersWrapper,
    GrokForm,
    Search,
    PWAPrompt,
    PWAUpdate,
    NotificationCount,
    PendingReviewCount
  },
  setup() {
    const user = inject(UserKey);
    let profileSidebarOpen = ref(false);
    let invitationsSidebarOpen = ref(false);
    let supportSidebarOpen = ref(false);
    let grokSidebarOpen = ref(false);
    let search = ref('');
    let closeSearch = ref(false);
    let searchKlass = ref('hidden');

    function clearSearch() {
      // Setting search to time string ensures that search prop watch is triggered
      // This then resets the search component value
      search.value = Date.now().toString();
      // await router.push({ name: 'groks', params: { search: '' } });
    }

    function navigateTo(page?: string) {
      // only clear search if logged in, to avoid unauthorized request
      if (user?.value) {
        clearSearch();
      }
      if (page) {
        router.push({name: page});
      } else {
        router.push({ name: 'about' });
      }
    }

    function openProfileSidebar(): void {
      profileSidebarOpen.value = true;
    }

    function openInvitationsSidebar(): void {
      invitationsSidebarOpen.value = true;
    }

    function openGrokSidebar(): void {
      grokSidebarOpen.value = true;
    }

    function openSupportSidebar(): void {
      supportSidebarOpen.value = true;
    }

    function closeSidebar(): void {
      profileSidebarOpen.value = false;
      invitationsSidebarOpen.value = false;
      grokSidebarOpen.value = false;
      supportSidebarOpen.value = false;
    }

    function openDiscord() : void {
      window.open('https://discord.gg/wbEw2GZ5aJ');
    }

    function toggleSearchBox() {
      searchKlass.value = searchKlass.value === 'block' ? 'hidden' : 'block';
    }

    return {
      user,
      closeSidebar,
      openProfileSidebar,
      openInvitationsSidebar,
      openGrokSidebar,
      openSupportSidebar,
      profileSidebarOpen,
      invitationsSidebarOpen,
      grokSidebarOpen,
      supportSidebarOpen,
      search,
      navigateTo,
      clearSearch,
      openDiscord,
      closeSearch,
      searchKlass,
      toggleSearchBox
    };
  }
});
export default Navigation;
