
import { defineComponent, ref } from 'vue';
import { mixin as VueClickAway } from 'vue3-click-away';

export const BaseDropdown = defineComponent({
  name: 'BaseDropdown',
  mixins: [VueClickAway],
  props: {
    closeOnSelect: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const active = ref(false);

    function toggle() {
      active.value = !active.value;
    }

    function close() {
      active.value = false;
    }

    function optionSelected() {
      if (props.closeOnSelect && active.value == true) {
        toggle();
      }
    }

    return { active, toggle, close, optionSelected };
  }
});

export default BaseDropdown;
