
import {defineComponent, ref} from 'vue';
import {useMutation, useQuery, useResult} from "@vue/apollo-composable";
import {USER_ACCOUNT} from "@/graphql/queries/userAccount";
import {TOKEN_POOL} from "@/graphql/queries/tokenPool";
import usePricePresenter from "@/composables/usePricePresenter";
import {EARN_TOKEN} from "@/graphql/mutations/earnToken";

export default defineComponent({
  name: 'AccountInfo',
  setup() {
    const {
      loading: userAccountLoading,
      result: userAccountResult
    } = useQuery(USER_ACCOUNT);

    const userAccount = useResult(userAccountResult, null, (data) => data.account);

    const {
      loading: tokenPoolLoading,
      result: tokenPoolResult
    } = useQuery(TOKEN_POOL);

    const tokenPool = useResult(tokenPoolResult, null, (data) => data.tokenPool);

    function purchaseSupporterPoints() {
      console.log('purchaseSupporterPoints is not implemented');
    }

    const { mutate: earnToken } = useMutation(EARN_TOKEN, {
      errorPolicy: 'all',
      refetchQueries: [{ query: USER_ACCOUNT }, { query: TOKEN_POOL }]
    });

    const earnTokenErrors = ref('');
    const earnTokenSuccess = ref('');

    function callEarnToken() {
      earnTokenErrors.value = '';
      earnTokenSuccess.value = '';
      return earnToken().then((response) => {
        if (response && response.errors) {
          earnTokenErrors.value = JSON.parse(JSON.stringify(response.errors));
        } else if (response && response.data.earnToken.tokens) {
          earnTokenSuccess.value = "Congratulations! You have earned " + response.data.earnToken.tokens + " tokens";
        }
      });
    }

    return {
      userAccount,
      purchaseSupporterPoints,
      userAccountLoading,
      tokenPool,
      tokenPoolLoading,
      usePricePresenter,
      callEarnToken,
      earnTokenErrors,
      earnTokenSuccess
    };
  }
});
