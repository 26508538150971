
import { computed, defineComponent, toRefs, ref, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import {required, email, maxLength} from '@vuelidate/validators';
import { useMutation } from '@vue/apollo-composable';
import { UPDATE_USER_ACCOUNT } from '@/graphql/mutations';
import { UserAccountPayload } from '@/types/types';
import { BaseInput } from '@/components';
import { getUserFromApolloStore } from '@/services/auth';
import BaseTextarea from "@/components/BaseInput/BaseTextarea.vue";
import TagInput from "@/components/TagInput.vue";
import {USERS} from "@/graphql/queries/users";

export default defineComponent({
  name: 'PersonalInfoForm',
  components: {BaseTextarea, BaseInput, TagInput },
  emits: ['close-personal-info-form'],
  props: {
    input: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const rules = computed(() => {
      return {
        input: {
          email: { required, email },
          username: { required },
          firstName: { required },
          lastName: { required },
          bio: { maxLength: maxLength(512) }
        }
      };
    });

    const isDisabled = ref(true);
    const inputUser = ref(props.input);

    const variables = reactive<UserAccountPayload>({
      input: {
        email: inputUser.value.email,
        username: inputUser.value.username,
        firstName: inputUser.value.firstName,
        lastName: inputUser.value.lastName,
        bio: inputUser.value.bio,
        website: inputUser.value.website
      }
    });

    const tags = ref(initialTags());

    function initialTags() {
      let tag : [string] = [''];
      if(props.input?.interests) {
        props.input.interests.forEach(x => tag.push(x['interest']));
        tag.shift();
      }
      return tag;
    }

    const v$ = useVuelidate(rules, variables);

    function validate() {
      v$.value.$validate();
      isDisabled.value = v$.value.$errors.length > 0;
    }

    let updatePersonalInfoErrors = ref([]);
    const { mutate: updatePersonalInfo, onDone } = useMutation(UPDATE_USER_ACCOUNT, {
      errorPolicy: 'all',
      refetchQueries: [{ query: USERS }]
    });

    function callUpdateInfo() {
      variables.input.interests = tags.value;

      return updatePersonalInfo(variables).then((response) => {
        if (response && response.data.updateUserAccount) {
          getUserFromApolloStore();
          ctx.emit('close-personal-info-form');
        } else if (response && response.errors) {
          isDisabled.value = true;
          updatePersonalInfoErrors.value = JSON.parse(JSON.stringify(response.errors));
        }
      });
    }

    function cancelForm() {
      ctx.emit('close-personal-info-form');
      return false;
    }

    return {
      isDisabled,
      v$,
      ...toRefs(variables),
      cancelForm,
      callUpdateInfo,
      validate,
      updatePersonalInfoErrors,
      tags
    };
  }
});
