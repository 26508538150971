import { gql } from '@apollo/client/core';

export const UPDATE_GROK = gql`
  mutation UpdateGrok($input: UpdateGrokInput!) {
    updateGrok(input: $input) {
      grok {
        id
      }
    }
  }
`;
