
import { defineComponent } from 'vue';

export const BaseTextarea = defineComponent({
  name: 'BaseTextarea',

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    value: {
      type: String,
      default: undefined
    },
    modelValue: {
      type: [String, Number],
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default() {
        return [];
      }
    },
    dataTestId: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    rows: {
      type: [String, Number],
      default: '3'
    },
    cols: {
      type: [String, Number],
      required: false
    },
    label: {
      type: String,
      required: true
    },
    layout: {
      type: String,
      default: 'vertical',
      validator: (value: string): boolean => {
        return ['vertical', 'horizontal'].indexOf(value) !== -1;
      }
    },
    title: {
      type: String,
      required: false
    },
    maxlength: {
      type: [String, Number],
      default: 512
    }
  },

  emits: ['update:modelValue']
});

export default BaseTextarea;
