
import { defineComponent, ref, inject } from 'vue';
import { UserKey } from '../../types/types';
import PersonalInfoForm from '@/pages/account/PersonalInfoForm.vue';
import PersonalInfoDescription from "@/pages/account/PersonalInfoDescription.vue";

export default defineComponent({
  components: { PersonalInfoForm, PersonalInfoDescription },
  emits: ['close-sidebar'],
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      required: false
    },
    showEditForm: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    let currentUser = inject(UserKey);
    let showForm = ref(props.showEditForm);

    const grokUser = props.user || currentUser;
    function closePersonalInfoForm() {
      showForm.value = false;
    }

    function closeSidebar() {
      ctx.emit('close-sidebar');
    }

    return {
      grokUser,
      showForm,
      closePersonalInfoForm,
      closeSidebar
    };
  }
});
