import { gql } from '@apollo/client/core';

export const USER_PENDING_REVIEW_COUNT = gql`
  query getUserPendingReviewCount($id: ID, $username: String) {
    user(id: $id, username: $username) {
      id
      reviewGroksCount
    }
  }
`;
