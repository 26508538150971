import { gql } from '@apollo/client/core';

export const USER_ACCOUNT = gql`
  query getUserAccount {
    account {
      id
      tokens
      creatorPoints
    }
  }
`;
