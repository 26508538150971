import { gql } from '@apollo/client/core';

export const UPDATE_INVITATION = gql`
  mutation UpdateInvitationMutation($input: UpdateInvitationInput!) {
    updateInvitation(input: $input) {
      invitation {
        accepted
        email
        id
        role {
          name
        }
      }
    }
  }
`;
