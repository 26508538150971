
import {defineComponent, reactive, ref, toRefs} from 'vue';
import {useMutation, useQuery, useResult} from "@vue/apollo-composable";
import AccountInfo from "@/components/UserProfile/AccountInfo.vue";
import Container from "@/components/Layout/Container.vue";
import {USER_ACCOUNT} from "@/graphql/queries/userAccount";
import {StakerPayload} from "@/types/types";
import {UPSERT_STAKER} from "@/graphql/mutations/upsertStaker";
import {STAKE_POOL} from "@/graphql/queries/userStakePool";
import StakePoolInfo from "@/components/UserProfile/StakePoolInfo.vue";

export default defineComponent({
  name: 'StakerInfo',
  components: {Container, AccountInfo, StakePoolInfo},
  props: {
    stakePoolId: {
      type: String,
      required: true
    },
    stakePoolUsername: {
      type: String,
      required: true
    },
    staker: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const stakerAccount = ref(props.staker?.account);

    const {
      onResult,
      loading: userAccountLoading,
      result: userAccountResult
    } = useQuery(USER_ACCOUNT);

    const account = useResult(userAccountResult, null, (data) => data.account);

    onResult(() => {
      userAccount.value = account.value;
    });

    const userAccount = ref(account.value);

    const stakeDisabled = ref(true);
    const variables = reactive<StakerPayload>({
      input: {
        stakePoolId: props.stakePoolId,
        tokens: 0,
        withdrawal: false
      }
    });

    function validate() : void {
      upsertStakerSuccess.value = null;
      upsertStakerErrors.value = [];
      let tokens = variables.input.tokens;
      tokens = typeof tokens === 'number' ? tokens : 0;

      const stakerTokens = stakerAccount.value?.tokens || 0;
      const userTokens = userAccount.value?.tokens || 0;
      const available = variables.input.withdrawal ? stakerTokens : userTokens;
      const valid = tokens > 0 && available >= tokens;

      stakeDisabled.value = !valid;
    }

    function cleanVariables() : void {
      // Type check says tokens must be number, but when form is cleared it is a blank string
      let tokens = variables.input.tokens;
      variables.input.tokens = typeof tokens === 'number' ? tokens : 0;
    }

    const upsertStakerErrors = ref([]);
    const upsertStakerSuccess = ref();

    const { mutate: upsertStaker } = useMutation(UPSERT_STAKER, {
      errorPolicy: 'all',
      refetchQueries: [{ query: USER_ACCOUNT }, { query: STAKE_POOL, variables: { id: props.stakePoolId } }]
    });

    function callUpsertStake() {
      cleanVariables();
      return upsertStaker(variables).then((response) => {
        stakeDisabled.value = true;
        if (response && response.errors) {
          upsertStakerErrors.value = JSON.parse(JSON.stringify(response.errors));
        } else if (response && response.data.upsertStaker) {
          upsertStakerSuccess.value = 'Stake successful!';
          variables.input.tokens = 0;
          variables.input.withdrawal = false;
          const staker = response.data.upsertStaker.staker;
          stakerAccount.value = staker.account;
        }
      });
    }

    return {
      stakerAccount,
      userAccount,
      userAccountLoading,
      ...toRefs(variables),
      stakeDisabled,
      validate,
      callUpsertStake,
      upsertStakerErrors,
      upsertStakerSuccess
    };
  }
});
