import { gql } from '@apollo/client/core';

export const USER_NOTIFICATION_COUNT = gql`
  query getUserNotificationCount($id: ID, $username: String) {
    user(id: $id, username: $username) {
      id
      unreadNotificationsCount
    }
  }
`;
