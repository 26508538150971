
import { defineComponent } from 'vue';

export const BaseInput = defineComponent({
  name: 'BaseInput',

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    type: {
      type: String,
      default: 'text',
      validator: (value: string): boolean => {
        return (
          [
            'text',
            'textarea',
            'number',
            'email',
            'password',
            'hidden',
            'date',
            'checkbox'
          ].indexOf(value) !== -1
        );
      }
    },
    value: {
      type: String,
      default: undefined
    },
    modelValue: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      required: true
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default() {
        return [];
      }
    },
    dataTestId: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },
    step: { type: Number, default: undefined },
    pattern: { type: String, default: undefined },
    rows: { type: [Number, String], default: undefined },
    layout: {
      type: String,
      default: 'vertical',
      validator: (value: string): boolean => {
        return ['vertical', 'horizontal'].indexOf(value) !== -1;
      }
    },
    title: {
      type: String,
      required: false
    }
  },

  emits: ['update:modelValue']
});

export default BaseInput;
