import { gql } from '@apollo/client/core';

export const STAKER = gql`
  query getStaker($id: ID!) {
    staker(id: $id) {
      id
      account {
        id
        tokens
        lockedTokens
      }
    }
  }
`;
