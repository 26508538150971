
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
  setup() {
    const installEvent = ref();
    const showPrompt = ref(false);

    onMounted(() => {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        installEvent.value = e;
        showPrompt.value = true;
      });
    });

    function installPWA() {
      if (installEvent.value) {
        installEvent.value.prompt();
        installEvent.value.userChoice.then((choice) => {
          showPrompt.value = false;
          if (choice.outcome === 'accepted') {
            console.log('installed');
          } else {
            console.log('dismissed');
          }
        });
      }
    }

    function dismissPrompt() {
      showPrompt.value = false;
    }

    return { showPrompt, installPWA, dismissPrompt };
  }
});
