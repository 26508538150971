
  import { defineComponent, ref } from 'vue';

  export const BaseNotification = defineComponent({
    name: "Notification",
    props: {
      open: {
        type: Boolean,
        default: true
      },
      isClosable: {
        type: Boolean,
        default: true
      },
      kind: {
        type: String,
        default: 'info',
        validator: (v: string) => {
          return ([
            'info', 'warn', 'error', 'success'
          ].includes(v));
        }
      },
      variant: {
        type: String,
        default: 'inline',
        validator: (v: string) => {
          return (
            [
              'inline',
              'toast'
            ].includes(v)
          );
        }
      }
    },
    emits: ['close-notification'],
    setup(props) {
      const isOpen = ref(props.open);

      function close() {
        isOpen.value = false;
      }

      return { close, isOpen };
    }
  });

  export default BaseNotification;
