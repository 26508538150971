import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: _normalizeClass(`btn ${_ctx.loginClass} px-1 md:px-2`),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/login')))
    }, "Login", 2),
    _createElementVNode("button", {
      class: _normalizeClass(`btn ${_ctx.registerClass} px-1 md:px-2`),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/register')))
    }, "Register", 2),
    _createElementVNode("button", {
      class: "btn btn-secondary px-1 md:px-2",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openSupportSidebar && _ctx.openSupportSidebar(...args)))
    }, "Support")
  ], 64))
}