import { gql } from '@apollo/client/core';

export const STAKE_POOL = gql`
  query getUserStakePool($id: ID!) {
    stakePool(id: $id) {
      id
      poolTotal
      stakersCount
    }
  }
`;
