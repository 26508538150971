
import {defineComponent} from 'vue';

export const BaseSidebar = defineComponent({
  name: 'BaseSidebar',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-sidebar'],
  setup(_, ctx) {

    function closeSidebar() {
      ctx.emit('close-sidebar');
    }
    return { closeSidebar };
  }
});

export default BaseSidebar;
