
import {defineComponent, PropType} from 'vue';
import { Grok } from "@/types/generated/types";

export const BaseDescription = defineComponent({
  name: 'BaseVideo',
  props: {
    grok: {
      type: Object as PropType<Grok>,
      required: true
    }
  }
});

export default BaseDescription;
