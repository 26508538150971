import { gql } from '@apollo/client/core';
import { GROK_DETAILS as GrokDetailsFragment } from '../fragments/grok';
import { COMMENT_DETAILS as CommentDetailsFragment } from '../fragments/comment';

export const GROK = gql`
  ${GrokDetailsFragment}
  ${CommentDetailsFragment}
  query getGrok($id: ID!) {
    grok(id: $id) {
      ...GrokDetails
      comments {
        ...CommentDetails
      }
    }
  }
`;
