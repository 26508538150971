import { createRouter, createWebHistory } from 'vue-router';
import { getUserFromApolloStore } from '../services/auth';
import { apolloClient } from '@/main';
import { USER_DETAILS } from '@/graphql/queries/userDetails';
import useToken from "@/composables/useToken";
import packageJson from "../../package.json";

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
        import(/* webpackChunkName: "home" */ '../pages/Home.vue'),
    children: [
      {
        name: 'activities',
        path: '',
        component: () =>
          import(/* webpackChunkName: "groks" */ '../pages/groks/Activities.vue'),
        props: true
      },
      {
        name: 'about',
        path: '',
        component: () =>
          import(/* webpackChunkName: "groks" */ '../pages/About.vue'),
        props: true,
        meta: { requiresAuth: false }
      },
      {
        name: 'groks',
        path: 'discover',
        component: () =>
            import(/* webpackChunkName: "groks" */ '../pages/groks/Groks.vue'),
        props: true
      },
      {
        name: 'review',
        path: 'review',
        component: () => import(/* webpackChunkName: "reviewGroks" */ '../pages/groks/ReviewGroks.vue'),
        props: true
      },
      {
        name: 'review-grok',
        path: 'review-grok',
        component: () => import(/* webpackChunkName: "reviewGrok" */ '../pages/groks/ReviewGrok.vue')
      },
      {
        name: 'users',
        path: 'users',
        component: () => import(/* webpackChunkName: "users" */ '../pages/users/Users.vue'),
        props: true
      },
      {
        name: 'notifications',
        path: '/notifications',
        component: () =>
          import(/* webpackChunkName: "Notifications" */ '../pages/Notifications.vue'),
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/user/:username',
    component: () =>
        import(/* webpackChunkName: "userProfile" */ '../pages/users/UserProfile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user/:username/:details',
    name: 'user-details',
    component: () =>
      import(/* webpackChunkName: "userProfile" */ '../pages/users/UserProfile.vue'),
    meta: { requiresAuth: true }
  },
  {
    name: 'grok',
    path: '/grok/:id',
    component: () =>
        import('../pages/groks/Grok.vue'),
      meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "loginAndRegistration" */ '../pages/register/Register.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "loginAndRegistration" */ '../pages/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/request-password-reset',
    name: 'request-password-reset',
    component: () =>
      import(/* webpackChunkName: "loginAndRegistration" */ '../pages/RequestPasswordReset.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () =>
      import(/* webpackChunkName: "loginAndRegistration" */ '../pages/PasswordReset.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "loginAndRegistration" */ '../pages/Logout.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "personalInfo" */ '../pages/account/PersonalInfo.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/invitations',
    name: 'invitations',
    component: () => import(/* webpackChunkName: "organization" */ '../pages/account/Invitations/UsersWrapper.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import(/* webpackChunkName: "grokForm" */ '../pages/groks/GrokForm.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import(/* webpackChunkName: "introduction" */ '../pages/Introduction.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "introduction" */ '../pages/settings/Terms.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../pages/settings/Privacy.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import(/* webpackChunkName: "cookies" */ '../pages/settings/Cookies.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const currentVersion = packageJson.version;
  fetch('/meta.json')
    .then((response) => response.json())
    .then((meta) => {
      const latestVersion = meta.version;
      const current = currentVersion === latestVersion;
      if (!current) {
        console.log('updating version from', currentVersion);
        console.log('to', latestVersion);
        window.location.reload();
      }
    });

  const { user } = getUserFromApolloStore();

  // No further checks to logout
  if (to.path === '/logout') {
    return next();
  }

  // Send to logout if token expired
  const token = useToken();

  // If auth not required
  if (!token && to.meta.requiresAuth === false) {
    return next();
  }

  // If user in the store
  if (user?.value?.id) {
    next();
    // Else make graphql call to get user
  } else if (token) {
    await apolloClient
      .query({ query: USER_DETAILS })
      .then((res) => {
        if (!res.data.user) {
          next({ name: 'about' });
        } else {
          user.value = res.data.user;
          next();
        }
      })
      .catch((e) => {
        next({ name: 'logout' });
        throw e;
      });
  } else {
    next({ name: 'about' });
  }
});

export default router;
