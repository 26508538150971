import { gql } from '@apollo/client/core';
import { USER_DETAILS as UserDetailsFragment } from '../fragments/user';

export const UPDATE_USER_ACCOUNT = gql`
  ${UserDetailsFragment}
  mutation UpdateUserAccount($input: UpdateUserAccountInput!) {
    updateUserAccount(input: $input) {
      user {
        ...UserDetails
      }
    }
  }
`;
