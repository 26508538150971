
import {computed, defineComponent, inject, PropType, reactive, ref, toRefs} from 'vue';
import {GrokPayload, GrokReferencePayload, PublicPathKey, UserKey} from '@/types/types';
import { useVuelidate } from '@vuelidate/core';
import { useMutation } from '@vue/apollo-composable';
import { useApi } from '@/services/api';
import axios from 'axios';
import { CREATE_GROK, UPDATE_GROK } from '@/graphql/mutations';
import { BaseInput } from '@/components';
import BaseTextarea from "@/components/BaseInput/BaseTextarea.vue";
import TagInput from "@/components/TagInput.vue";
import {GROKS} from "@/graphql/queries";
import router from "@/router";
import { uuid } from "vue-uuid";
import {helpers, required} from "@vuelidate/validators";

export default defineComponent({
  components: {BaseTextarea, BaseInput, TagInput },
  emits: ['close-sidebar'],
  props: {
    contents: String,
    grok: {
      type: Object as PropType<GrokPayload>,
      required: false
    }
  },
  setup(props, ctx) {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    // fieldRequired is used in the computed rules
    const fieldRequired = (value) => value && value.length > 0;

    const rules = computed(() => {
      // inputs for new grok start as undefined, and do not trigger required error
      return {
        input: {
          s3OriginPath: {
            fieldRequired: helpers.withMessage('Valid file required', required)
          },
          title: {
            fieldRequired: helpers.withMessage('Title required', required)
          },
          description: {
            fieldRequired: helpers.withMessage('Description required', required)
          }
        }
      };
    });

    const showInstructions = ref(false);
    const user = inject(UserKey);

    const fileName = ref();

    const isDisabled = ref(true);
    const fileUploading = ref(false);
    const fileError = ref(false);

    function references() {
      let ref : [GrokReferencePayload] = [{ description: '', url: ''}];
      if(props.grok?.input.references) {
        props.grok.input.references.forEach(x => ref.push(Object.assign({}, x)));
        ref.shift();
      }
      return ref;
    }

    const tags = ref(initialTags());

    function initialTags() {
      let tag : [string] = [''];
      if(props.grok?.input.keywords) {
        props.grok.input.keywords.forEach(x => tag.push(x['keyword']));
        tag.shift();
      }
      return tag;
    }

    const variables = reactive<GrokPayload>({
      input: {
        id: props.grok?.input.id,
        title: props.grok?.input.title,
        description: props.grok?.input.description,
        s3OriginPath: props.grok?.input.s3OriginPath,
        references: references(),
        preview: false
      }
    });

    const v$ = useVuelidate(rules, variables);

    function validate() {
      v$.value.$validate();
      createUploadSuccess.value = false;
      isDisabled.value = v$.value.$errors.length > 0;
    }

    let createUploadErrors = ref([]);
    let createUploadSuccess = ref(false);
    let fileUploadId = ref('');

    const { mutate: createGrok } = useMutation(CREATE_GROK, {
      errorPolicy: 'all',
      refetchQueries: [{ query: GROKS, variables: { scope: 'user' } }]
    });

    const { mutate: updateGrok } = useMutation(UPDATE_GROK, {
      errorPolicy: 'all',
      refetchQueries: [{ query: GROKS, variables: { scope: 'user' } }]
    });

    function validateFile(files: [File]) {
      fileUploading.value = true;
      let file = files[0];
      let video = document.createElement('video');
      let timeLimit = 60 * 4;
      video.preload = 'metadata';
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);

        if (video.duration > timeLimit) {
          fileUploading.value = false;
          fileError.value = true;

          return fileError.value;
        } else {
          uploadFile(file);
        }
      };
    }

    function uploadFile(file: File) {
      fileName.value = file.name;
      let filetype = '.' + file.name.split('.').pop();
      let storedFilename = uuid.v1() + filetype.toLowerCase();

      const { get } = useApi('api/v1/uploads/s3_direct_posts?filename=' + storedFilename);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      get().then((res: any) => {
        let path = res.data.fields.key;
        let url = res.data.url;
        const formData = new FormData();
        Object.keys(res.data.fields).forEach((key) => {
          formData.append(key, res.data.fields[key]);
        });
        formData.append('file', file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          }
        };
        const aws = axios.create();
        aws.post(url, formData, config).then(() => {
          // TODO error handle
          fileUploading.value = false;
          fileError.value = false;
          variables.input.s3OriginPath = path;
          validate();
        });
      });
    }

    function callUploadFile() {
      variables.input.keywords = tags.value;

      return createGrok(variables).then((response) => {
        if (response && response.data.createGrok) {
          ctx.emit('close-sidebar');
          if (response.data.createGrok['grok']['published']) {
            let route = '/user/' + user?.value.username;
            router.push(route);
          } else {
            let route = '/user/' + user?.value.username + '/pending';
            router.push(route);
          }
        } if (response && response.errors) {
          createUploadErrors.value = JSON.parse(JSON.stringify(response.errors));
        }
      });
    }

    function callUpdateGrok() {
      variables.input.keywords = tags.value;

      return updateGrok(variables).then((response) => {
        if (response && response.data.updateGrok) {
          ctx.emit('close-sidebar');
        } else if (response && response.errors) {
          createUploadErrors.value = JSON.parse(JSON.stringify(response.errors));
        }
      });
    }

    function addField() {
      variables?.input?.references?.push({description: '', url: ''});
    }

    function removeField(index: number) {
      variables?.input?.references?.splice(index, 1);
      validate();
    }

    return {
      validateFile,
      callUploadFile,
      callUpdateGrok,
      createUploadErrors,
      createUploadSuccess,
      fileUploadId,
      ...toRefs(variables),
      v$,
      publicPath: inject(PublicPathKey),
      isDisabled,
      validate,
      addField,
      removeField,
      fileName,
      fileUploading,
      fileError,
      tags,
      user,
      showInstructions
    };
  }
});
