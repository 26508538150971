import { gql } from '@apollo/client/core';

export const UPSERT_STAKER = gql`
  mutation UpsertStaker($input: UpsertStakerInput!) {
    upsertStaker(input: $input) {
      staker {
        id
        user {
          id
        }
        account {
          id
          tokens
        }
      }
    }
  }
`;
