
import {defineComponent, ref} from "vue";

export default defineComponent({
  setup() {
    const refreshing = ref(false);
    const updateExists = ref(false);
    const registration = ref();
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', updateAvailable, { once: true });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!refreshing.value) {
        refreshing.value = true;
        window.location.reload();
      }
    });

    function updateAvailable(event) {
      console.log('update available');
      registration.value = event.detail;
      // Try auto-update for now
      // updateExists.value = true;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (registration.value?.waiting) {
        console.log('SW skip waiting');
        // send message to SW to skip the waiting and activate the new SW
        registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    }

    // Called when the user accepts the update
    function refreshApp() {
      console.log('refreshApp');
      updateExists.value = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (registration.value?.waiting) {
        console.log('SW skip waiting');
        // send message to SW to skip the waiting and activate the new SW
        registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    }

    return { updateExists, refreshApp };
  }
});
