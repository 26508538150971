import { gql } from '@apollo/client/core';
import { GROK_DETAILS as GrokDetailsFragment } from '../fragments/grok';
import { PAGE_INFO as PageInfoFragment } from '../fragments/pageInfo';

export const PLAYLIST = gql`
  ${GrokDetailsFragment}
  ${PageInfoFragment}
  query getPlaylist($id: ID!) {
    playlist(id: $id) {
      pageInfo {
        ...PageInfoDetails
      }
      nodes {
        ...GrokDetails
      }
      edges {
        node {
          ...GrokDetails
        }
      }
    }
  }
`;
