import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "flex flex-row items-center m-4" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "text-red-600" }
const _hoisted_5 = {
  key: 0,
  class: "flex mt-4"
}
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_input = _resolveComponent("base-input")!
  const _component_TagInput = _resolveComponent("TagInput")!
  const _component_base_textarea = _resolveComponent("base-textarea")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updatePersonalInfo && _ctx.updatePersonalInfo(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", null, [
        _createVNode(_component_base_input, {
          modelValue: _ctx.input.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input.email) = $event)),
          disabled: _ctx.disabled,
          label: "Email",
          type: "text",
          errors: _ctx.v$.input.email.$errors,
          onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validate())),
          "data-test-id": "personal-info-edit-email"
        }, null, 8, ["modelValue", "disabled", "errors"]),
        _createVNode(_component_base_input, {
          modelValue: _ctx.input.username,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.input.username) = $event)),
          disabled: _ctx.disabled,
          label: "Username",
          type: "text",
          errors: _ctx.v$.input.username.$errors,
          onKeyup: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validate())),
          "data-test-id": "personal-info-edit-username"
        }, null, 8, ["modelValue", "disabled", "errors"]),
        _createVNode(_component_base_input, {
          modelValue: _ctx.input.firstName,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.input.firstName) = $event)),
          disabled: _ctx.disabled,
          label: "First Name",
          type: "text",
          errors: _ctx.v$.input.firstName.$errors,
          onKeyup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.validate())),
          "data-test-id": "personal-info-edit-first-name"
        }, null, 8, ["modelValue", "disabled", "errors"]),
        _createVNode(_component_base_input, {
          modelValue: _ctx.input.lastName,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.input.lastName) = $event)),
          disabled: _ctx.disabled,
          label: "Last Name",
          type: "text",
          errors: _ctx.v$.input.lastName.$errors,
          onKeyup: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validate())),
          "data-test-id": "personal-info-edit-last-name"
        }, null, 8, ["modelValue", "disabled", "errors"]),
        _createVNode(_component_TagInput, {
          modelValue: _ctx.tags,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.tags) = $event)),
          name: "Interest",
          onTagsChanged: _ctx.validate
        }, null, 8, ["modelValue", "onTagsChanged"]),
        _createVNode(_component_base_textarea, {
          modelValue: _ctx.input.bio,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.input.bio) = $event)),
          disabled: _ctx.disabled,
          label: "Bio",
          rows: "10",
          errors: _ctx.v$.input.bio.$errors,
          onKeyup: _cache[10] || (_cache[10] = ($event: any) => (_ctx.validate()))
        }, null, 8, ["modelValue", "disabled", "errors"]),
        _createVNode(_component_base_input, {
          modelValue: _ctx.input.website,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.input.website) = $event)),
          disabled: _ctx.disabled,
          label: "Website",
          type: "text",
          onKeyup: _cache[12] || (_cache[12] = ($event: any) => (_ctx.validate()))
        }, null, 8, ["modelValue", "disabled"])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.updatePersonalInfoErrors)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.updatePersonalInfoErrors, (error) => {
              return (_openBlock(), _createElementBlock("div", {
                key: error[0]
              }, [
                _createElementVNode("p", _hoisted_4, _toDisplayString(error), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "btn btn-cancel",
            onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.cancelForm && _ctx.cancelForm(...args))),
            "data-test-id": "cancel-personal-info"
          }, " Cancel "),
          _createElementVNode("button", {
            disabled: _ctx.isDisabled,
            class: "btn btn-primary",
            onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.callUpdateInfo && _ctx.callUpdateInfo(...args))),
            "data-test-id": "update-personal-info"
          }, " Update ", 8, _hoisted_6)
        ]))
      : _createCommentVNode("", true)
  ], 32))
}