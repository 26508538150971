import { gql } from '@apollo/client/core';

export const TOKEN_POOL = gql`
  query getTokenPool($orgName: String) {
    tokenPool(orgName: $orgName) {
      id
      tokenPriceCents
      tokenPricePoints
    }
  }
`;
