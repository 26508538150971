import { gql } from '@apollo/client/core';

export const INVITATIONS = gql`
  query getInvitations(
    $includeAccepted: Boolean
  ) {
    invitations(
      includeAccepted: $includeAccepted
    ) {
      email
      accepted
      id
      userId
      role {
        name
      }
    }
  }
`;
