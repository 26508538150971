<template>
  <div class="container px-2 mx-auto pt-4 mb-4">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>