
import { useResult } from '@vue/apollo-composable';
import useGraphqlQuery from "@/composables/useGraphqlQuery";
import {NOMINATED_USERS} from "@/graphql/queries/NominatedUsers";
import router from "@/router";

export default {
  emits: ['close-sidebar'],
  setup(_, ctx) {
    const {
      result: usersResult,
      error: usersQueryError,
      loading: usersLoading
    } = useGraphqlQuery(NOMINATED_USERS);

    const users = useResult(usersResult, null, (data) => data.user.nominatedUsers);

    function viewUser(username: string): void {
      ctx.emit('close-sidebar');
      let route = '/user/' + username;
      router.push(route);
    }

    return {
      users,
      usersQueryError,
      usersLoading,
      usersResult,
      viewUser
    };
  }
};
