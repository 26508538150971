
import {defineComponent, inject, PropType, reactive, ref, toRefs} from 'vue';
import {PlaylistGrokPayload, UserKey} from "@/types/types";
import {useMutation, useQuery, useResult} from "@vue/apollo-composable";
import {Grok} from "@/types/generated/types";
import {PLAYLISTS} from "@/graphql/queries/playlists";
import {PLAYLIST} from "@/graphql/queries/playlist";
import {CREATE_PLAYLIST_GROK} from "@/graphql/mutations/createPlaylistGrok";
import PlaylistForm from "@/components/UserProfile/PlaylistForm.vue";

export default defineComponent({
  components: { PlaylistForm },
  emits: ['close-sidebar'],
  props: {
    grok: {
      type: Object as PropType<Grok>,
      required: true
    }
  },
  setup(props, ctx) {
    const user = inject(UserKey);

    const {
      result,
      loading
    } = useQuery(PLAYLISTS, { username: user?.value.username });

    const playlistOptions = useResult(result, null);

    const disabled = ref(true);

    const variables = reactive<PlaylistGrokPayload>({
      input: {
        playlistId: '',
        grokId: props.grok.id
      }
    });

    function validate() {
      createPlaylistGrokErrors.value = [];
      disabled.value = variables.input.playlistId.length === 0;
    }

    let createPlaylistGrokErrors = ref([]);

    const { mutate: createPlaylistGrok } = useMutation(CREATE_PLAYLIST_GROK, {
      errorPolicy: 'all',
      refetchQueries: [{ query: PLAYLIST, variables: { id: variables.input.playlistId } }]
    });

    function callCreatePlaylistGrok() {
      return createPlaylistGrok(variables).then((response) => {
        if (response && response.errors) {
          createPlaylistGrokErrors.value = JSON.parse(JSON.stringify(response.errors));
        } else {
          closeSidebar();
        }
      });
    }

    function closeSidebar() {
      ctx.emit('close-sidebar');
    }

    return {
      disabled,
      callCreatePlaylistGrok,
      createPlaylistGrokErrors,
      ...toRefs(variables),
      validate,
      playlistOptions,
      loading,
      closeSidebar,
      user
    };
  }
});
