<template>
  <div>
    <base-input
      v-model="newTag"
      :label="`${name}s`"
      :placeholder="`Press enter or tab to add a new ${name}`"
      type="text"
      autocomplete="off"
      @keydown.prevent.enter="addTag(newTag)"
      @keydown.prevent.tab="addTag(newTag)"
    />
  <div>
    <span
        v-for="(tag, index) in tags"
        :key="tag"
      >
        <span
          v-if="tag.length !== 0"
          class="tag inline-block bg-blue-50 m-0.5"
          :class="{ duplicate: tag === duplicate }"
        >
          {{ tag }}
          <button class="delete" @click="removeTag(index)">x</button>
        </span>
      </span>
    </div>
  </div>
</template>
<script>
import {ref, watch, nextTick, defineComponent} from "vue";
import { BaseInput } from '@/components';

export default defineComponent({
  components: {BaseInput},
  emits: ['tags-changed'],
  props: {
    modelValue: { type: Array, default: () => [] },
    name: { type: String, default: 'Tag' }
  },
  setup(props, ctx) {
    // Tags
    const tags = ref(props.modelValue);
    const newTag = ref('');

    function addTag(tag) {
      if (!tag) return; // prevent empty tag
      // return early if duplicate
      if (tags.value.includes(tag)) {
        handleDuplicate(tag);
        return;
      }
      tags.value.push(tag);
      newTag.value = ""; // reset newTag
    }

    function removeTag(index) {
      tags.value.splice(index, 1);
    }

    // handling duplicates
    const duplicate = ref(null);
    function handleDuplicate(tag) {
      duplicate.value = tag;
      setTimeout(() => (duplicate.value = null), 1000);
      newTag.value = "";
    }

    const onTagsChange = () => {
      // emit value on tags change
      ctx.emit('tags-changed');
    };
    watch(tags, () => nextTick(onTagsChange), { deep: true });

    return {
      tags,
      newTag,
      addTag,
      removeTag,
      duplicate,
    };
  },
});
</script>
<style scoped>
.tag {
  padding: 5px;
  border-radius: 4px;
  color: white;
  white-space: nowrap;
  transition: 0.1s ease background;
}
input {
  width: 100%;
}
.delete {
  color: red;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  float: right;
  padding-left: 1rem;
}
@keyframes shake {
  10%,
  90% {
    transform: scale(0.9) translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: scale(0.9) translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: scale(0.9) translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: scale(0.9) translate3d(4px, 0, 0);
  }
}
.tag.duplicate {
  background: rgb(235, 27, 27);
  animation: shake 1s;
}
.count {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: block;
  font-size: 0.8rem;
  white-space: nowrap;
}
</style>