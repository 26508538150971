
import {defineComponent} from 'vue';
import router from "@/router";

export default defineComponent({
  emits: ['open-profile-sidebar', 'open-invitations-sidebar', 'open-support-sidebar', 'open-grok-sidebar', 'clear'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    function openProfileSidebar(): void {
      ctx.emit('open-profile-sidebar');
    }

    function openInvitationsSidebar(): void {
      ctx.emit('open-invitations-sidebar');
    }

    function openSupportSidebar(): void {
      ctx.emit('open-support-sidebar');
    }

    function openGrokSidebar(): void {
      ctx.emit('open-grok-sidebar');
    }

    function logout(): void {
      router.push('/logout');
    }

    function navigateTo(page) {
      ctx.emit('clear');
      router.push({name: page});
    }

    function navigateToUser(details) {
      ctx.emit('clear');
      let route = '/user/' + props.user.username + details;
      router.push(route);
    }


    return {
      logout,
      openProfileSidebar,
      openInvitationsSidebar,
      openSupportSidebar,
      openGrokSidebar,
      navigateTo,
      navigateToUser
    };
  }
});
