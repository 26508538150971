
import { defineComponent, provide } from 'vue';
import { Navigation } from '@/components';
import { getUserFromApolloStore } from './services/auth';
import { UserKey, PublicPathKey } from './types/types';

export default defineComponent({
  components: { Navigation },
  setup() {
    const { user } = getUserFromApolloStore();

    provide(UserKey, user);
    provide(PublicPathKey, process.env.BASE_URL);
  }
});
