import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-md text-red-400 font-bold -ml-1 -mt-1 md:mt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.count && _ctx.count > 0)
    ? (_openBlock(), _createElementBlock("p", _hoisted_1, "*"))
    : _createCommentVNode("", true)
}