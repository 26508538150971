import { gql } from '@apollo/client/core';
import { COMMENT_DETAILS as CommentDetailsFragment } from '../fragments/comment';

export const COMMENTS = gql`
  ${CommentDetailsFragment}
  query getComments($type: String!, $id: ID!) {
    comments(type: $type, id: $id) {
      ...CommentDetails
    }
  }
`;
