/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { computed, ref } from 'vue';
import { AUTH_TOKEN } from './auth';
import useToken from "@/composables/useToken";

export const useApi = (endpoint: string): any => {
  const token = useToken() || '';
  const baseUrl = process.env.VUE_APP_API_BASE_URL;

  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: token
    }
  });

  const data = ref();
  const loading = ref(false);
  const error = ref();

  const post = (payload?: Record<string, any>) => {
    loading.value = true;
    error.value = undefined;

    return api
      .post(endpoint, payload)
      .then((res) => {
        data.value = res;
      })
      .catch((e) => {
        error.value = e.response;

        throw e;
      })
      .finally(() => (loading.value = false));
  };

  const put = (payload?: Record<string, any>) => {
    loading.value = true;
    error.value = undefined;

    return api
      .put(endpoint, payload)
      .then((res) => {
        data.value = res.data;
        data.value[AUTH_TOKEN] = res.headers.authorization;
      })
      .catch((e) => {
        error.value = e.response;

        throw e;
      })
      .finally(() => (loading.value = false));
  };

  const get = (query?: Record<string, any>, config?: AxiosRequestConfig) => {
    loading.value = true;
    error.value = undefined;

    let queryString = '';

    if (query) {
      queryString =
        '?' +
        Object.entries(query)
          .map(
            ([key, value]) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          )
          .join('&');
    }

    return api
      .get(endpoint + queryString, config)
      .then((res) => (data.value = res.data))
      .catch((e) => {
        error.value = e;

        throw e;
      })
      .finally(() => (loading.value = false));
  };

  const del = () => {
    loading.value = true;
    error.value = undefined;

    return api
      .delete(endpoint)
      .then((res) => (data.value = res.data))
      .catch((e) => {
        error.value = e;

        throw e;
      })
      .finally(() => (loading.value = false));
  };

  const errorMessage = computed(() => {
    if (error.value) {
      return error.value.data.error;
    }
    return null;
  });

  const errorDetails = computed(() => {
    if (error.value && error.value.response) {
      return error.value.data.error;
    }
    return null;
  });

  const errorFields = computed(() => {
    if (error.value && Array.isArray(error.value.data.error)) {
      return (error.value.data.error as string[]).reduce(
        (acc: Record<string, any>, msg: string) => {
          const [field] = msg.split(' ');

          if (!acc[field]) {
            acc[field] = [];
          }

          acc[field].push(msg);

          return acc;
        },
        {}
      );
    }
    return null;
  });

  const computedClasses = (key: string) => {
    if (
      errorFields.value != null &&
      Object.prototype.hasOwnProperty.call(errorFields.value, key)
    ) {
      return ['border-red-600', 'bg-red-200', 'text-red-900'];
    }
    return ['border-grey-600', 'bg-white', 'text-gray-900'];
  };

  return {
    loading,
    data,
    error,
    get,
    post,
    put,
    del,
    errorMessage,
    errorDetails,
    errorFields,
    computedClasses
  };
};
