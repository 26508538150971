import { gql } from '@apollo/client/core';

export const CREATE_PLAYLIST_GROK = gql`
  mutation CreatePlaylistGrok($input: CreatePlaylistGrokInput!) {
    createPlaylistGrok(input: $input) {
      playlist {
        id
      }
    }
  }
`;
