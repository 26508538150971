import { gql } from '@apollo/client/core';
import { USER_DETAILS as UserDetailsFragment } from '../fragments/user';

export const USERS = gql`
  ${UserDetailsFragment}
  query getUsers($search: String) {
    users(search: $search) {
      ...UserDetails
    }
  }
`;
