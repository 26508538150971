
import {defineComponent, inject, ref} from 'vue';
import {UserKey} from "@/types/types";
import AccountInfo from "@/components/UserProfile/AccountInfo.vue";
import StakerInfo from "@/pages/account/StakerInfo.vue";
import FollowInfo from "@/components/UserProfile/FollowInfo.vue";
import StakePoolInfo from "@/components/UserProfile/StakePoolInfo.vue";

export default defineComponent({
  name: 'PersonalInfoDescription',
  components: {FollowInfo, AccountInfo, StakerInfo, StakePoolInfo },
  emits: ['close-sidebar'],
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup(props, ctx) {
    let user = inject(UserKey);
    let currentUser = ref(props.user.id === user?.value.id);

    function closeSidebar() {
      ctx.emit('close-sidebar');
    }

    return { currentUser, closeSidebar };
  }
});
