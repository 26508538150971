import { gql } from '@apollo/client/core';

export const PAGE_INFO = gql`
  fragment PageInfoDetails on PageInfo {
    endCursor
    startCursor
    hasPreviousPage
    hasNextPage
  }
`;
