import {useQuery, UseQueryReturn} from "@vue/apollo-composable";
import {DocumentNode} from "graphql";
import {OperationVariables} from "@apollo/client";
import {VariablesParameter} from "@vue/apollo-composable/dist/useQuery";
import useToken from "@/composables/useToken";

export default function useGraphqlQuery(query: DocumentNode, variables?: VariablesParameter<OperationVariables>): UseQueryReturn<any, any> {
  // Check token present. Will route to logout if not
  useToken();

  return variables ? useQuery(query, variables) : useQuery(query);
}
