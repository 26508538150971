
import {defineComponent, reactive, ref, watch} from 'vue';
import {BaseInput} from '@/components';
import router from "@/router";
import Toggle from '@vueform/toggle';
import {useRoute} from "vue-router";
import {mixin as VueClickAway} from "vue3-click-away";

export default defineComponent({
  components: { BaseInput, Toggle },
  emits: ['close'],
  mixins: [VueClickAway],
  props: {
    search: {
      type: String,
      required: false
    }
  },
  setup(props, ctx) {
    const route = useRoute();

    watch(() => props.search, () => {
      searchOptions.search = '';
    });
    watch(() => route.name, () => {
      grokSearch.value = (route.name !== 'users');
    });

    const grokSearch = ref(route.name !== 'users');

    const searchOptions: { search: string } = reactive({
      search: ''
    });

    function cleanSearchTerm(searchTerm: string | null | undefined) : string {
      return searchTerm ? searchTerm : '';
    }

    let isSearchDisabled = ref(true);

    function validate() {
      isSearchDisabled.value = false;
    }

    function callSubmitSearch() {
      const searchTerm = cleanSearchTerm(searchOptions.search).toString();
      if(grokSearch.value) {
        router.push({ name: 'groks', params: { search: searchTerm }});
      } else {
        router.push({ name: 'users', params: { search: searchTerm }});
      }
      close();
    }

    function close() {
      ctx.emit('close');
    }

    return {
      searchOptions,
      isSearchDisabled,
      validate,
      callSubmitSearch,
      grokSearch,
      close
    };
  }
});
