
import {defineComponent} from 'vue';
import {useQuery, useResult} from "@vue/apollo-composable";
import {STAKE_POOL} from "@/graphql/queries/userStakePool";

export default defineComponent({
  name: 'StakePoolInfo',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      loading: stakePoolLoading,
      result: stakePoolResult
    } = useQuery(STAKE_POOL, { id: props.id });

    const pool = useResult(stakePoolResult, null);

    return { pool, stakePoolLoading };
  }
});
