
import {defineComponent, ref, watch} from 'vue';
import {useRoute} from "vue-router";

export default defineComponent({
  emits: ['open-support-sidebar'],
  setup(_, ctx) {
    const route = useRoute();
    watch(() => route.name, () => {
      setButtons(route.name === 'register');
    });

    function setButtons(register: boolean) : void {
      if(register) {
        loginClass.value = 'btn-secondary';
        registerClass.value = 'btn-primary';
      } else {
        loginClass.value = 'btn-primary';
        registerClass.value = 'btn-secondary';
      }
    }
    const loginClass = ref('');
    const registerClass = ref('');
    setButtons(route.name === 'register');

    function openSupportSidebar(): void {
      ctx.emit('open-support-sidebar');
    }

    return { openSupportSidebar, loginClass, registerClass };
  }
});
