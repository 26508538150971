import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inline-block p-0 m-0 w-screen-1/2 max-w-prose" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggle = _resolveComponent("Toggle")!
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      "data-test-id": "email-invite-form",
      class: "flex items-center w-auto",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.callSubmitSearch && _ctx.callSubmitSearch(...args)), ["prevent"]))
    }, [
      _createVNode(_component_Toggle, {
        modelValue: _ctx.grokSearch,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.grokSearch) = $event)),
        onLabel: "Groks",
        offLabel: "Users",
        classes: {
          toggle: 'flex w-14 h-5 rounded-full relative cursor-pointer transition items-center box-content border-2 text-xs leading-none text-sm italic font-medium',
          toggleOn: 'bg-blue-50 border-blue-50 text-gray-900',
          toggleOff: 'bg-blue-50 border-blue-50 text-gray-900 justify-end',
          container: 'inline-block rounded-full outline-none focus:ring focus:ring-blue-50 focus:ring-opacity-30',
          handle: 'inline-block bg-gray-50 w-5 h-5 top-0 rounded-full absolute transition-all'
        }
      }, null, 8, ["modelValue"]),
      _createVNode(_component_base_input, {
        label: "Discover...",
        required: true,
        "hide-label": true,
        modelValue: _ctx.searchOptions.search,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchOptions.search) = $event)),
        class: "w-full pl-2",
        onChange: _ctx.validate
      }, null, 8, ["modelValue", "onChange"]),
      _createElementVNode("button", {
        disabled: _ctx.isSearchDisabled,
        type: "submit",
        class: "invisible",
        onClick: [
          _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.callSubmitSearch && _ctx.callSubmitSearch(...args))),
          _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.isSearchDisabled && _ctx.isSearchDisabled(...args)), ["prevent"]))
        ]
      }, null, 8, _hoisted_2)
    ], 32)
  ]))
}