import { gql } from '@apollo/client/core';
import { USER_DETAILS as UserDetailsFragment } from '../fragments/user';

export const USER_DETAILS = gql`
  ${UserDetailsFragment}
  query getUserDetails($id: ID, $username: String) {
    user(id: $id, username: $username) {
      ...UserDetails
    }
  }
`;
