import { gql } from '@apollo/client/core';
import { GROK_DETAILS as GrokDetailsFragment } from '../fragments/grok';
import { PAGE_INFO as PageInfoFragment } from '../fragments/pageInfo';

export const GROKS = gql`
  ${GrokDetailsFragment}
  ${PageInfoFragment}
  query getGroks($search: String, $scope: String, $userid: ID, $first: Int, $after: String, $before: String) {
    groks(search: $search, scope: $scope, userid: $userid, first: $first, after: $after, before: $before) {
      pageInfo {
        ...PageInfoDetails
      }
      nodes {
        ...GrokDetails
      }
      edges {
        node {
          ...GrokDetails
        }
      }
    }
  }
`;
