import { gql } from '@apollo/client/core';

export const DELETE_FOLLOW = gql`
  mutation DeleteUserFollow($input: DeleteUserFollowInput!) {
    deleteUserFollow(input: $input) {
      userFollow {
        id
      }
    }
  }
`;
