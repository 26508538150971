import { gql } from '@apollo/client/core';

export const CREATE_GROK = gql`
  mutation CreateGrok($input: CreateGrokInput!) {
    createGrok(input: $input) {
      grok {
        id
        published
      }
    }
  }
`;
