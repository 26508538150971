import { gql } from '@apollo/client/core';

export const USER_FOLLOW_DETAILS = gql`
  query getUserFollowDetails($id: ID, $username: String) {
    user(id: $id, username: $username) {
      id
      username
      followingUsersCount
      followedByUsersCount
      followingYou
      followedByYou
      publishedGrokCount
      unpublishedGrokCount
      publishedPlaylistCount
      playlistCount
    }
  }
`;
