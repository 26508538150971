
import { defineComponent } from 'vue';

const Loading = defineComponent({
  name: 'Loading',
  props: {
    message: {
      type: String,
      default: 'Loading...'
    }
  }
});

export default Loading;
