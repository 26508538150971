import { gql } from '@apollo/client/core';
import { USER_DETAILS as UserDetailsFragment } from '../fragments/user';

export const GROK_DETAILS = gql`
  ${UserDetailsFragment}
  fragment GrokDetails on Grok {
    id
    title
    description
    encoded
    encodingError
    thumbnailUrl
    mp4Url
    status
    s3OriginPath
    viewCount
    publishedAt
    favoriteCount
    commentsCount
    userFavorite
    user {
      ...UserDetails
    }
    users {
      id
      firstName
      lastName
      username
    }
    references {
      id
      description
      url
    }
    keywords {
      id
      keyword
    }
    creatorPlaylists {
      id
      title
      user {
        id
        username
      }
    }
  }
`;
