import { gql } from '@apollo/client/core';

export const CREATE_FOLLOW = gql`
  mutation CreateUserFollow($input: CreateUserFollowInput!) {
    createUserFollow(input: $input) {
      userFollow {
        id
      }
    }
  }
`;
