
import {useQuery, useResult} from '@vue/apollo-composable';
import {defineComponent} from 'vue';
import {USER_PENDING_REVIEW_COUNT} from "@/graphql/queries/userPendingReviewCount";

export default defineComponent({
  setup() {
    const {
      result
    } = useQuery(USER_PENDING_REVIEW_COUNT, null, { pollInterval: 1000 * 60 * 5 });

    const count = useResult(result, null, (data) => data.user.reviewGroksCount);

    return { count };
  }
});
