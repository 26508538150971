import {relayStylePagination} from "@apollo/client/utilities";

export const typePolicies = {
  typePolicies: {
    Query: {
      fields: {
        groks: relayStylePagination(),
        activities: relayStylePagination(),
        notifications: relayStylePagination()
      }
    }
  }
};
