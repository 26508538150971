
import { defineComponent, computed, ref, reactive } from 'vue';
import BaseInput from '../../../components/BaseInput/BaseInput.vue';
import { useMutation } from '@vue/apollo-composable';
import { CREATE_INVITATION } from '../../../graphql/mutations/createInvitation';
import { required, email } from '@vuelidate/validators';
import useValidate from '@vuelidate/core';
import { INVITATIONS } from '@/graphql/queries/invitations';
import { CreateInvitationInput } from '@/types/generated/types';

export default defineComponent({
  components: { BaseInput },
  setup() {
    let newInvite = reactive<CreateInvitationInput>({
      email: '',
      role: ''
    });

    const rules = computed(() => {
      return { email: { required, email } };
    });

    const isAddEmailButtonDisabled = ref(true);
    const v$ = useValidate(rules, newInvite);

    function validate() {
      v$.value.$validate();
      isAddEmailButtonDisabled.value = v$.value.$errors.length > 0;
    }

    let createInvitationErrors: Array<string> = [];

    const { mutate: createInvitation } = useMutation(CREATE_INVITATION, {
      errorPolicy: 'all',
      refetchQueries: [{ query: INVITATIONS }]
    });

    function callCreateInvite() {
      const variables = {
        input: newInvite
      };

      return createInvitation(variables).then((response) => {
        newInvite.email = '';
        if (response && response.errors) {
          createInvitationErrors = JSON.parse(JSON.stringify(response.errors));
        }
      });
    }

    return {
      newInvite,
      callCreateInvite,
      validate,
      v$,
      isAddEmailButtonDisabled,
      createInvitationErrors
    };
  }
});
