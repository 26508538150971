
import { defineComponent } from 'vue';
import NominatedUsers from './NominatedUsers.vue';
import EmailForm from './EmailForm.vue';
import PendingInvites from './PendingInvites.vue';

export default defineComponent({
  components: {
    NominatedUsers,
    EmailForm,
    PendingInvites
  },
  emits: ['close-sidebar'],
  setup(_, ctx) {
    function closeSidebar(): void {
      ctx.emit('close-sidebar');
    }

    return { closeSidebar };
  }
});
