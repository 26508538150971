
import {defineComponent, ref} from 'vue';
import {useQuery, useResult} from "@vue/apollo-composable";
import {STAKER} from "@/graphql/queries/staker";
import {BaseSidebar} from "@/components";
import Stake from "@/components/Stakes/Stake.vue";

export default defineComponent({
  name: 'StakerInfo',
  components: { BaseSidebar, Stake },
  props: {
    stakePoolId: {
      type: String,
      required: true
    },
    stakePoolUsername: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      result: stakerQueryResult,
      loading: stakerQueryLoading
    } = useQuery(STAKER, { id: props.stakePoolId });

    const staker = useResult(stakerQueryResult, null);

    const stakeSidebarOpen = ref(false);

    function openStakeSidebar() {
      stakeSidebarOpen.value = true;
    }

    function closeSidebar() {
      stakeSidebarOpen.value = false;
    }

    return {
      staker,
      stakerQueryLoading,
      stakeSidebarOpen,
      openStakeSidebar,
      closeSidebar
    };
  }
});
