import { gql } from '@apollo/client/core';

export const CREATE_PLAYLIST = gql`
  mutation CreatePlaylist($input: CreatePlaylistInput!) {
    createPlaylist(input: $input) {
      playlist {
        id
      }
    }
  }
`;
